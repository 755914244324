<template>
  <div
    v-if="$can('update', 'abilitiestemplates')"
  >
    <b-row>
      <b-col
        cols="24"
        xl="12"
        lg="12"
        md="12"
      >
        <abilities-templates-permissions-card :template-data="templateData" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import abilitiesTemplatesStoreModule from '../abilitiesTemplatesStoreModule'
import AbilitiesTemplatesPermissionsCard from './AbilitiesTemplatesPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,

    AbilitiesTemplatesPermissionsCard,
  },
  data() {
    return {
      templateData: {},
    }
  },
  setup() {
    const TEMPLATE_APP_STORE_MODULE_NAME = 'app-abilities-templates'
    // Register module
    if (!store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(TEMPLATE_APP_STORE_MODULE_NAME, abilitiesTemplatesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_APP_STORE_MODULE_NAME)
    })
  },
  created() {
    const abilitiesTemplates = JSON.parse(localStorage.getItem('abilities_templates'))

    console.log(router.currentRoute.params.templatename)
    let tmp = {}
    abilitiesTemplates.forEach(item => {
      if (item.templatename === router.currentRoute.params.templatename) {
        tmp = { templatename: item.templatename, templateJSON: [] }
        item.templateJSON.forEach(item2 => {
          tmp.templateJSON.push(item2)
        })
      }
    })
    console.log(tmp)
    this.templateData = tmp
  },
}
</script>

<style>

</style>
